<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <h4 class="card-title"><b>LISTA DE PERSONAS</b></h4>
            
          </template>
          <div class="text-right px-2 mt-3"  >
            <div class="d-flex justify-content-end" style="margin-top: -5rem"  >
              <vs-button floating gradient v-b-tooltip.hover.bottom title="Agregar persona" class="p-1" success icon  @click="$router.push('persona_agregar')"><i class="fas fa-plus"></i> </vs-button>

            </div>
          </div>
          <div class="table-responsive px-4 mt-3 " >
            <table id="datatable_persona" class="table table-bordered table-responsive-md table-striped text-center rounded">
              <thead class="rounded" >
                <tr class="rounded"  >
                  <th>Numero Documento</th>
                  <th>Nombre</th>
                  <th>Contraseña reportes</th>
                  <th>Cargo</th>
                  <th>Estado</th>
                  <th>Ver más</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>{{ item.numeroDocumento }}</td>
                  <!-- <td><img class="avatar-35 rounded" src="https://www.w3schools.com/w3images/avatar2.png" alt=""></td> -->
                  <td>
                    {{
                      item.nombres +
                      " " +
                      (item.segundoNombre != null ? item.segundoNombre : "") +
                      " " +
                      item.apellidos +
                      " " +
                      (item.segundoApellido != null ? item.segundoApellido : "")
                    }}
                  </td>
                  <td>{{ item.contrasenaReportes }}</td>
                  <td>{{ obtenerUbicacionPersona(item) }}</td>
                  <td>
                    <div class="d-flex flex-column">
                      <!-- <p>{{ item.estado == 1 ? "Activo" : "Inactivo" }}</p> -->
                      <div class="toggle-switch" @click="TogglePersonaEstado(item.id)">
                        <input type="checkbox" v-model="item.estado" class="toggle-input" />
                        <label class="toggle-label"></label>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center"  >
                      <vs-button floating icon gradient class=" " style="width: 2.3rem; height: 2.3rem;" v-b-modal.my-modal @click="infoForPerson(item)"><i style="font-size:1rem;" class="fa-solid fa-info  "></i></vs-button>
                    </div>
                  </td>

                  <!-- <td>
                    <button v-b-modal.modal-1 class="btn btn-secondary">
                      Ver más
                    </button>
                  </td> -->
                  <!-- <b-modal id="bv-modal-example" hide-footer>
                    <template #modal-title>
                      <h6>Informacion de Persona</h6>
                    </template>
                    <div class="d-block text-center">
                      <h2>Hello From This Modal!</h2>
                    </div>
                    <div class="text-right">
                      <b-button
                        class="mt-3"
                        @click="$bvModal.hide('bv-modal-example')"
                        >Cerrar</b-button
                      >
                    </div>
                  </b-modal> -->
                  <td style="width: 10%">
                    <div class="d-flex justify-content-center px-0 mx-0">
                      <!-- <vs-button
                        v-b-tooltip="{
                          title: `Ingreso / Retiro`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                         
                        style="background-color: #f4c000"
                        warn
                        icon
                        v-b-modal.modalNew
                        @click="infoForPerson(item)"
                      >
                        <i class="fas fa-solid fa-check"></i>
                      </vs-button>
                      <vs-button
                        v-b-tooltip="{
                          title: `Registro novedades`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                         
                        style="background-color: #6f8ac4"
                        success
                        icon
                        @click="gotoRegistroNovedades(item)"
                      >
                        <i class="fas fa-solid fa-check"></i>
                      </vs-button>
                      <vs-button
                        v-b-tooltip="{
                          title: `Hoja de vida`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                         
                        icon
                        @click="$router.push('hoja_vida/' + item.id)"
                      >
                        <i class="fas fa-solid fa-file"></i>
                      </vs-button>
                      <vs-button
                        v-b-tooltip="{
                          title: `historia laboral`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                         
                        warn
                        icon
                        @click="$router.push('Persona_historia_laboral/' + item.id)"
                      >
                        <i class="fas fa-solid fa-clock"></i>
                      </vs-button> -->
                      <vs-button
                      floating
                        v-b-tooltip="{
                          title: `Editar`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        gradient
                        success
                        icon
                        @click="$router.push('Persona_editar/' + item.id)"
                      >
                        <i class="fas fa-solid fa-pen-to-square"></i>
                      </vs-button>
                      <!-- <vs-button
                        v-b-tooltip="{
                          title: `Gestionar accesos`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        v-if="persona.rol.nivel == 4"
                         
                        icon
                        danger
                        @click="$router.push('GestionAccesos/' + item.id)"
                      >
                        <i class="fas fa-solid fa-lock"></i>
                      </vs-button> -->
                    </div>

                    <!-- <div class="row px-2">
                      <div
                        v-b-tooltip="{
                          title: `Registro novedades`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        class="col px-0 mx-0"
                        @click="$router.push('RegistroNovedades/' + item.id)"
                      >
                        <i class="zoom fas fa-solid fa-check px-1 text-light"></i>
                      </div>
                      <div
                        v-b-tooltip="{
                          title: `Hoja de vida`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        class="col px-0 mx-0"
                        @click="$router.push('hoja_vida/' + item.id)"
                      >
                        <i class="zoom fas fa-solid fa-file px-1 text-primary"></i>
                      </div>
                      <div
                        v-b-tooltip="{
                          title: `historia laboral`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        class="col px-0 mx-0"
                        @click="$router.push('Persona_historia_laboral/' + item.id)"
                      >
                        <i class="zoom fas fa-solid fa-clock px-1 text-warning"></i>
                      </div>
                      <div
                        v-b-tooltip="{
                          title: `Editar`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        class="col px-0 mx-0"
                        @click="$router.push('Persona_editar/' + item.id)"
                      >
                        <i class="zoom fas fa-solid fa-pen px-1 text-success"></i>
                      </div>
                      <div
                        v-b-tooltip="{
                          title: `Gestionar accesos`,
                          placement: 'top',
                          customClass: 'font_tooltip',
                        }"
                        v-if="persona.rol.nivel == 4"
                        class="col px-0 mx-0"
                        @click="$router.push('GestionAccesos/' + item.id)"
                      >
                        <i class="zoom fas fa-solid fa-lock px-1 text-danger"></i>
                      </div>

                     
                    </div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <b-modal id="my-modal" size="lg" title="Modal title" ok-title="Save Changes" cancel-title="Close">
              <p>Modal body text goes here.</p>
            </b-modal> -->
          <b-modal title="Detalles Persona" id="my-modal" body-bg-variant="light" header-bg-variant="primary" footer-bg-variant="primary" size="lg"  >
            <div class="card p-3"   >
              <h2>Informacion Personal</h2>
              <p>Nombres: {{ infoForPersonData.nombres + " " + (infoForPersonData.segundoNombre != null ? infoForPersonData.segundoNombre : "") }}</p>
              <p>Apellidos: {{ infoForPersonData.apellidos + " " + (infoForPersonData.segundoApellido != null ? infoForPersonData.segundoApellido : "") }}</p>
              <p>Tipo de Documento: {{ infoForPersonData.tipoDoc }}</p>
              <p>Numero de Documento: {{ infoForPersonData.doc }}</p>
              <p>Estado Civil: {{ infoForPersonData.estadoCivil }}</p>
            </div>
            <div class="card p-3"   >
              <h2>Contacto</h2>
              <p>Direccion: {{ infoForPersonData.direccion }}</p>
              <p>Municipio: {{ infoForPersonData.municipio }}</p>
              <p>Telefono Fijo: {{ infoForPersonData.telFijo }}</p>
              <p>Telefono Movil: {{ infoForPersonData.telMovil }}</p>
            </div>
            <div class="card p-3"   >
              <h2>Seguridad</h2>
              <p>Correo: {{ infoForPersonData.correo }}</p>
            </div>
            <div class="card p-3">
              <h2>Empresa</h2>
              <p>Rol en Empresa: {{ infoForPersonData.rol }}</p>
              <p>Empresa: {{ infoForPersonData.empresa }}</p>
              <p>Sucursal: {{ infoForPersonData.sucursal }}</p>
              <p>Area: {{ infoForPersonData.area }}</p>
            </div>
           
            
            
          </b-modal>
          <b-modal hide-header id="modalNew" class="modal-body body-modal-custom" size="md" hide-footer>
            <div class="p-3">
              <form @submit.prevent="guardarIngresoRetiro()">
                <div data-v-6fc0398c="" class="d-flex align-items-center">
                  <div data-v-6fc0398c="" style="background-color: rgb(223, 18, 46); width: 7px; border-radius: 0px 20px; height: 30px"></div>
                  <h4
                    data-v-6fc0398c=""
                    style="font-size: 1.5rem; font-weight: bolder; margin-left: 5px; max-width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                  >
                    Registro de ingreso / Retiro
                  </h4>
                </div>
                <div class="pt-3">
                  <div class="row pb-2">
                    <div class="col-6 pl-0 pr-1">
                      <div class="px-3 py-2 bg-dark" style="border-radius: 10px">
                        <label class="pb-0 mb-0 text-light" style="font-size: 13px">Nombres:</label>
                        <div class="d-flex">
                          <!-- <div data-v-6fc0398c=""  class="mt-1 mr-1" style="width: 7px; height: 7px; border-radius: 50%; background-color: red;"></div> -->
                          {{ infoForPersonData.nombres + " " + infoForPersonData.segundoNombre }}
                        </div>
                      </div>
                    </div>
                    <div class="col-6 pl-1 pr-0">
                      <div class="px-3 py-2 bg-dark" style="border-radius: 10px">
                        <label class="pb-0 mb-0 text-light" style="font-size: 13px">Apellidos:</label>
                        <div class="d-flex">
                          <!-- <div data-v-6fc0398c=""  class="mt-1 mr-1" style="width: 7px; height: 7px; border-radius: 50%; background-color: red;"></div> -->
                          {{ infoForPersonData.apellidos + " " + infoForPersonData.segundoApellido }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-for="itn of NPerFechaIngRet" :key="itn.id">
                    <div class="col-12 bg-dark" style="border-radius: 10px" v-if="itn.tipo == 'Ingreso'">
                      <div class="d-flex pt-2 px-1">
                        <i class="fa-solid fa-calendar-plus h2"></i>
                        <div class="pl-2">
                          <span class="pb-0 mb-0 text-light mt-3" style="font-size: 13px">Fecha contratación:</span>
                          <b class="">
                            {{ $moment(itn.fecha).tz("America/Bogota").format("DD-MM-YYYY") }}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 bg-dark mt-2" style="border-radius: 10px" v-if="itn.tipo != 'Ingreso'">
                      <div class="d-flex pt-2 px-1">
                        <i class="fa-regular fa-calendar-minus h2"></i>
                        <div class="pl-2">
                          <span class="pb-0 mb-0 text-light" style="font-size: 13px">Fecha retiro:</span>
                          <b v-if="infoForPersonData.fechaRetiro != null" class=" ">
                            {{ $moment(itn.fecha).tz("America/Bogota").format("DD-MM-YYYY") }}
                          </b>
                          <b v-else class=" "> -- -- -- </b>
                          <div class="d-flex">
                            <div data-v-6fc0398c="" class="my-1 mx-1" style="width: 7px; height: 7px; border-radius: 50%; background-color: red"></div>
                            <span class="d-flex">{{ listaCausalRetiro.filter((x) => (x.id = itn.perCausalRetiroId))[0].causal }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="infoForPersonData.fechaRetiro == null" class="mt-3">
                  <label class="m-0 pl-1 pb-1" for="">Fecha retiro</label>
                  <b-form-input v-model="dataIngresoRetiro.fecha" required type="date"></b-form-input>
                </div>
                <div v-if="infoForPersonData.fechaRetiro != null" class="mt-3">
                  <label class="m-0 pl-1 pb-1" for="">Fecha ingreso</label>
                  <b-form-input v-model="dataIngresoRetiro.fecha" required type="date"></b-form-input>
                </div>
                <div v-if="infoForPersonData.fechaRetiro == null" class="mt-2">
                  <label class="m-0 pl-1 pb-1" for="">Causal de retiro</label>
                  <b-form-select required v-model="selected" value-field="id" text-field="causal" :options="listaCausalRetiro"></b-form-select>
                </div>
                <div class="d-flex justify-content-end">
                  <vs-button type="submit" animation-type="vertical" primary   icon class="px-2">
                    <i class="fa-solid fa-floppy-disk mr-1"></i>
                    Guardar
                    <template #animate>
                      <i class="fa-solid fa-floppy-disk"></i>
                    </template>
                  </vs-button>
                  <vs-button @click.prevent="$bvModal.hide('modalNew')" animation-type="vertical" danger   icon class="px-2">
                    <i class="fa-solid fa-xmark mr-1"></i>
                    Cancelar
                    <template #animate>
                      <i class="fa-solid fa-xmark"></i>
                    </template>
                  </vs-button>
                </div>
              </form>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "../../../config/pluginInit";
const Swal = require("sweetalert2");
export default {
  name: "DataTable",
  data() {
    return {
      options: [
        { id: 1, value: null, text: "Please select an option" },
        { id: 2, value: "a", text: "This is First option" },
        { id: 3, value: "b", text: "Selected Option" },
        { id: 4, value: { C: "3PO" }, text: "This is an option with object value" },
        { id: 5, value: "d", text: "This one is disabled", disabled: true },
      ],
      tableName: "Persona",
      listaItems: [],
      persona: {},
      empresaId: "",
      listaPersonaUbicacion: [],
      infoForPersonData: {
        nombres: null,
        segundoNombre: null,
        apellidos: null,
        segundoApellido: null,
        tipoDoc: null,
        doc: null,
        estadoCivil: null,
        direccion: null,
        municipio: null,
        telFijo: null,
        telMovil: null,
        correo: null,
        empresa: null,
        rol: null,
        sucursal: null,
        area: null,
      },
      dataIngresoRetiro: {},
      selected: null,
      listaCausalRetiro: [],
      NPerFechaIngRet: [],
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
    await this.getData();
    await this.getPersonaUbicacion();
    await this.obtenerListaCausalRetiro();
    core.index();
    window.$("#datatable_persona").DataTable();
  },
  methods: {
    async GetNPerFechaIngRetByPersonaId(PersonaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NPerFechaIngRet/GetNPerFechaIngRetByPersonaId/" + PersonaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.NPerFechaIngRet = res;
        } else {
          this.NPerFechaIngRet = [];
        }
      } catch (error) {
        this.NPerFechaIngRet = [];
        console.log("err", error);
      }
    },
    async obtenerListaCausalRetiro() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PerCausalRetiro/GetPerCausalRetiro",
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaCausalRetiro = res;
        } else {
          this.listaCausalRetiro = [];
        }
      } catch (error) {
        this.listaCausalRetiro = [];
        console.log("err", error);
      }
    },
    async guardarIngresoRetiro() {
      try {
        let dataIngresoRetiro = {
          FechaEvento: this.$moment(new Date()).tz("America/Bogota").format("YYYY-MM-DDTHH:mm"),
          Fecha: this.$moment(this.dataIngresoRetiro.fecha).tz("America/Bogota").format("YYYY-MM-DDTHH:mm"),
          PerCausalRetiroId: this.selected,
          PersonaId: this.infoForPersonData.personaId,
          Tipo: this.infoForPersonData.fechaRetiro == null ? "Retiro" : "Ingreso",
        };
        await this.$store.dispatch("hl_post", {
          path: "NPerFechaIngRet/CreateNPerFechaIngRet",
          data: dataIngresoRetiro,
        });
        this.$bvModal.hide("modalNew");
        await this.getData();
        await this.getPersonaUbicacion();

        this.$toast.success("Registro almacenado correctamente", {
          position: "top-right",
          timeout: 2984,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } catch (error) {
        alert(error);
      }
    },
    gotoRegistroNovedades(it) {
      console.log(it);
      if (it.salario == null || it.salario == 0) {
        Swal.fire("Error", "Empleado no tiene salario asignado", "Error");
      } else {
        this.$router.push("RegistroNovedades/" + it.id);
      }
    },
    async infoForPerson(data) {
      console.log(data);
      this.infoForPersonData = {};
      this.infoForPersonData.nombres = data.nombres;
      this.infoForPersonData.segundoNombre = data.segundoNombre;
      this.infoForPersonData.apellidos = data.apellidos;
      this.infoForPersonData.segundoApellido = data.segundoApellido;
      this.infoForPersonData.tipoDoc = data.tipoDocumento.descripcion;
      this.infoForPersonData.doc = data.numeroDocumento;
      this.infoForPersonData.estadoCivil = data.estadoCivil.descripcion;
      this.infoForPersonData.direccion = data.direccion;
      this.infoForPersonData.municipio = data.municipio.descripcion;
      this.infoForPersonData.telFijo = data.telefonoFijo;
      this.infoForPersonData.telMovil = data.telefonoMovil;
      this.infoForPersonData.correo = data.correo;
      this.infoForPersonData.rol = data.rol.descripcion;
      this.infoForPersonData.empresa = data.empresa.nombre;
      this.infoForPersonData.fechaContratacion = data.fechaContratacion;
      this.infoForPersonData.fechaRetiro = data.fechaRetiro;
      this.infoForPersonData.personaId = data.id;

      await this.GetNPerFechaIngRetByPersonaId(data.id);
      this.getUbicacionPersonaDatallesModal(data);
    },
    async TogglePersonaEstado(id) {
      Swal.fire({
        title: "Seguro que quiere cambiar el estado?",
        text: "Puede cambiar el estado las veces que lo requiera!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          await this.$store.dispatch("hl_get", {
            path: "Persona/TogglePersonaEstado/" + id,
          });
          await this.getData();

          Swal.fire("Listo!", "Se ha cambiado el estado correctamente.", "success");
        }
      });
    },
    obtenerUbicacionPersona(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      if (io > 0) {
        let result = this.listaPersonaUbicacion.indexOf(io);
        console.log("cargo ", result);
      }
      return io ? io.cargo.nombre : "";
    },
    getUbicacionPersonaDatallesModal(item) {
      let io = this.listaPersonaUbicacion.find((x) => x.persona.id == item.id);
      // console.log("cargo detalle ", io);
      this.infoForPersonData.sucursal = io.sucursal.descripcion;
      this.infoForPersonData.area = io.area.descripcion;
    },
    async getPersonaUbicacion() {
      try {
        //   console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/List/" + this.empresaId,
        });
        console.log("post empresas...", res);
        if (res.length > 0) {
          this.listaPersonaUbicacion = res;
        } else {
          this.listaPersonaUbicacion = [];
        }
      } catch (error) {
        this.listaPersonaUbicacion = [];
        console.log("err", error);
      }
    },
    async getData() {
      console.log(this.persona.empresa.id);
      try {
        console.log("get ListPersonaAll...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersonaAll/" + this.empresaId,
        });
        console.log("ListPersonaAll...", res);
        if (res.length > 0) {
          this.listaItems = res;
        } else {
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err ListPersonaAll", error);
      }
    },
  },
};
</script>

<style>
/* Estilos para el interruptor */
.toggle-switch {
  position: relative;
  width: 60px;
  height: 52px;
}

/* Estilos para el input oculto */
.toggle-input {
  display: none;
}

/* Estilos para la etiqueta del interruptor (background del interruptor) */
.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 50px;
  height: 34px;
  background-color: #d43862 !important;
  /* Color del fondo cuando está inactivo */
  border-radius: 34px;
  transition: background-color 0.4s;
}

/* Estilos para la etiqueta cuando el interruptor está activado */
.toggle-input:checked + .toggle-label {
  background-color: #343d93  !important
  /* Color del fondo cuando está activo */
}

/* Estilos para el indicador (la "perilla" del interruptor) */
.toggle-label::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  background-color: #8fa4df !important;
  border-radius: 50%;
  transition: transform 0.4s;
}

/* Estilos para el indicador cuando el interruptor está activado */
.toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}
.font_tooltip {
  font-size: 14px;
  font-style: italic;
}
</style>
